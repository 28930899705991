/* eslint-disable */
import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { useEffect } from 'react';
import { useAuth } from "react-oidc-context";
import APIEndpoints from 'src/components/api/APIEndpoints.js'
import { init as initApm } from '@elastic/apm-rum'
import { AppLoading } from '../index.js'

const loading = (
  <div className="pt-3 text-center">
    <div id="beck-spinner-1" className="beck-spinner loading" role="status"></div>
  </div>
)

const DefaultLayout = React.lazy(() => import('../../layout/DefaultLayout'))
const LoginPage = React.lazy(() => import('../../pages/login/login'))
const RegisterPage = React.lazy(() => import('../../pages/login/register'))

const Authorization = ({ children }) => {
  const auth = useAuth();
  const [authorized, setAuthorized] = React.useState();
  const [authorizationMessage, setAuthorizationMessage] = React.useState("");

  const handleRegistration = () => {
      setAuthorized(true);
      setAuthorizationMessage("")
  };

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      (async () => {
          try {
              const accessToken = auth.user?.access_token;
              //console.log("Authorizing... ")
              const response = await fetch(APIEndpoints("authorize"), {
                                 method: 'GET',
                                 headers: {
                                   'Accept': 'application/json',
                                   'Content-Type': 'application/json',
                                   'Authorization': 'Bearer ' + accessToken,
                                 },
                               })
              const response_data = await response.json()
              if (response_data.result === "True") {
                handleRegistration()
              }
              else {
                setAuthorized(false);
                setAuthorizationMessage('Der Benutzername "'+ auth.user?.profile.name + '" konnte nicht authorisiert werden. Beantragen Sie eine Freischaltung für "Frage den Grüneberg".')
              }
          } catch (e) {
              setAuthorized(false);
              setAuthorizationMessage('Fehler beim Authorisieren von "'+ auth.user?.profile.name + '". Bitte versuchen Sie es später noch erneut.')
              console.error(e);
          }
      })();}
      else {
        //setAuthorized(0);
      }
  }, []);

  if (authorized === undefined) {
    return <AppLoading />
  }

  if (authorized) {
        return (
          <div>
            <BrowserRouter>
                <Suspense fallback={loading}>
                  <Routes>
                    <Route path="*" name="Home" element={<DefaultLayout />} />
                  </Routes>
                </Suspense>
            </BrowserRouter>
          </div>
        );
  }
  else
    return <RegisterPage error={authorizationMessage} onRegistration={handleRegistration}/>
}

export default Authorization
