/* eslint-disable */
import React from 'react'
import { Link } from 'react-router-dom'
import * as dayjs from 'dayjs'
import { CFooter, CLink } from '@coreui/react'

const AppFooter = () => {
  return (
  <CFooter>
    <div>

      <Link to="/disclaimer"> Disclaimer </Link>
      <span> &#124; </span>

      <Link to="/privacy"> Datenschutzhinweise </Link>
      <span> &#124; </span>

      <Link to="/tdm"> TDM-Hinweise </Link>
      <span> &#124; </span>

      <Link to="/impressum"> Impressum </Link>
    </div>
    <div>
      <span>Powered by </span>
      <Link to="https://www.legal-data-analytics.com" target="_blank" rel="noopener noreferrer"> LDA Legal Data Analytics GmbH </Link>
      <span> &copy; {dayjs().year()}</span>
    </div>
  </CFooter>
  )
}

export default React.memo(AppFooter)
