/* eslint-disable */
import '../scss/style.scss'

const AppLoading = () => {
  <div className="pt-3 text-center">
    <div id="beck-spinner-1" className="beck-spinner loading" role="status"></div>
  </div>
}

export default AppLoading
