/* eslint-disable */

export default function APIEndpoints(task) {

    if (task === "ask")
      return process.env.REACT_APP_LDA_AILAB_SERVER + "/api/qna";
    else if (task === "search")
      return process.env.REACT_APP_LDA_AILAB_SERVER + "/api/search";
    else if (task === "chatbot")
      return process.env.REACT_APP_LDA_AILAB_SERVER + "/api/chatbot";
    else if (task === "rating")
      return process.env.REACT_APP_LDA_AILAB_SERVER + '/api/rate'
    else if (task === "get_document")
      return process.env.REACT_APP_LDA_AILAB_SERVER + '/api/get_document'
    else if (task === "translate")
      return process.env.REACT_APP_LDA_AILAB_SERVER + '/api/translate'
    else if (task === "authorize")
      return process.env.REACT_APP_LDA_AILAB_SERVER + '/api/authorize'
    else if (task === "register")
      return process.env.REACT_APP_LDA_AILAB_SERVER + '/api/register'

}
