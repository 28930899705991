/* eslint-disable */
import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { AuthProvider } from 'react-oidc-context';

const oidcConfig = {
  authority: process.env.REACT_APP_CHB_AUTHORITY,
  client_id: process.env.REACT_APP_CHB_CLIENT_ID,
  client_secret: process.env.REACT_APP_CHB_CLIENT_SECRET,
  redirect_uri: process.env.REACT_APP_CHB_REDIRECT_ORIGIN,
  post_logout_redirect_uri: process.env.REACT_APP_CHB_REDIRECT_ORIGIN,
  scope: "openid profile read:modulerights write:bonusnumbers"
};

createRoot(document.getElementById('root')).render(
  <AuthProvider {...oidcConfig}>
    <Provider store={store}>
          <App />
    </Provider>
  </AuthProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
