/* eslint-disable */
import React from 'react'
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavItem,
} from '@coreui/react'
import {
  cilAccountLogout,
  cilCreditCard,
  cilCommentSquare,
  cilEnvelopeOpen,
  cilFile,
  cilLockLocked,
  cilSettings,
  cilTask,
  cilUser,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useAuth } from "react-oidc-context";

const AppHeaderDropdown = () => {
  const auth = useAuth();
  const dispatch = useDispatch()
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [userName, setUserName] = React.useState("");

  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle color="secondary">Eingeloggt als:  {auth.user?.profile.name}</CDropdownToggle>
      <CDropdownMenu>
        <CDropdownItem href="/logout">Logout</CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

export default AppHeaderDropdown
