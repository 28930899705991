/* eslint-disable */
import React from 'react'
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";

const Home = React.lazy(() => import('./views/home/Home'))

// LDA elastic
const ESSearch = React.lazy(() => import('./views/lda-search/LDAElasticSearch'))

// LDA ask
const LDAAsk = React.lazy(() => import('./views/lda/Ask'))
const LDAChat = React.lazy(() => import('./views/lda/Chat'))
const LDACHBTranslate = React.lazy(() => import('./views/lda/CHBTranslate'))
const LDADisclaimer = React.lazy(() => import('./views/lda/Disclaimer'))
const LDADocumentView = React.lazy(() => import('./views/lda/DocumentView'))
const PrivacyPage = React.lazy(() => import('./views/lda/PrivacyPage'))
const Hints = React.lazy(() => import('./views/lda/Hints'))
const Impressum = React.lazy(() => import('./views/lda/Impressum'))
const TDM = React.lazy(() => import('./views/lda/TDM'))

// pages
const Page404 = React.lazy(() => import('./pages/page404/page404'))
const Login = React.lazy(() => import('./pages/login/login'))
const Logout = React.lazy(() => import('./pages/logout'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/home', name: 'Home', element: Home },
  //{ path: '/login', name: 'Login', element: Login },
  { path: '/search', name: 'LDA Search', element: ESSearch },
  { path: '/ask', name: 'LDA Ask', element: LDAAsk },
  { path: '/document', name: 'LDA Document View', element: LDADocumentView },
  { path: '/chat', name: 'LDA Chat', element: LDAChat },
  { path: '/translate', name: 'LDA Translation', element: LDACHBTranslate },
  { path: '/disclaimer', name: 'LDA Prompt', element: LDADisclaimer },
  { path: '/privacy', name: 'LDA Prompt', element: PrivacyPage },
  { path: '/impressum', name: 'LDA Prompt', element: Impressum },
  { path: '/tdm', name: 'LDA Prompt', element: TDM },
  { path: '/hints', name: 'LDA Prompt', element: Hints },
  { path: '/logout', name: 'Logout', element: Logout},
  { path: '*', name: 'Not Found', element: Page404},
]

export default routes
